<template>
  <div v-if="devMode" class="dev-tools">
    {{ currentScene.name }}
    <p>
      <span>X (yaw) deg : </span
      ><data @click="copyData">{{ clickPos[0].deg }}</data> °
    </p>
    <p>
      <span>Y (pitch) deg : </span
      ><data @click="copyData">{{ clickPos[1].deg }}</data> °
    </p>
    <p>
      <span>X (yaw) rad : </span><data @click="copyData">{{ clickPos[0].rad }}</data>
    </p>
    <p>
      <span>Y (pitch) rad : </span><data @click="copyData">{{ clickPos[1].rad }}</data>
    </p>
    <div class="scenes">
      <p
        v-for="(scene, i) in scenes.scenes"
        :key="scene.id"
        class="scene"
        :class="{ current: currentScene.name === scene.name }"
        @click="changeScene(i)"
      >
        {{ i + " - id: " + scene.id + " - name: " + scene.name }}
      </p>
    </div>
    <br>
    <button @click="togglePdf('demo.pdf')">Toggle PDF</button>
    <span class="mdi mdi-home"></span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import eventStore from "@/services/eventStore";
export default {
  name: "devTools",
  computed: {
    ...mapState(["devMode", "currentScene", "clickPos", "scenes"]),
  },
  methods: {
    copyData() {
      if (this.devMode) {
        //const str = event.target.innerHTML;
        const str = `yaw: degToRad(${this.clickPos[0].deg}),
                      pitch: degToRad(${this.clickPos[1].deg}),`
        const el = document.createElement("textarea");
        el.value = str;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        navigator.clipboard.writeText(str);
        document.body.removeChild(el);
      }
    },
    changeScene(id) {
      this.$store.state.nextRoom = id;
      console.log(id);
    },
    togglePdf(file) {
      eventStore.togglePdf(file);
    },
  },
  watch: {
    clickPos(newValue) {
      if (newValue){
        this.copyData()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "devTools";
</style>
