<template>
  <div id="popup-video">
    <div class="close-btn" @click="close">
      <i class="mdi mdi-close"></i>
    </div>
    <div class="video-container">
      <iframe
        :src="video"
        width="960"
        height="540"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen

      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "PopupVideo",
  computed: {
    video() {
      return this.$store.state.currentVideo;
    },
  },
  methods: {
    close() {
      this.$store.state.videoPlayerIsOpen = false;
      this.$store.state.currentVideo = null;
      let sound = document.querySelector('#ambiant-sound')
      sound.volume = 0.1;
    },
  },
  mounted() {
    document.querySelector(' .video-container').addEventListener('click', (evt) => {
      evt.stopPropagation()
    })
    document.querySelector('#popup-video').addEventListener('click',() => {
          this.close()
        }
    )
  },
};
</script>

<style lang="scss" scoped>
@import "PopupVideo";
</style>
