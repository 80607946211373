<template>
  <div id="user-detail">
    <div id="user-detail-container">
      <div class="close-button" @click="closeDetail"> <i class="mdi mdi-close"></i> </div>
      <div class="content-wrapper">
        <div class="photo">
          <img :src="'img/bios_photos/' + user.image" :alt="user.firstName + '_' + user.lastName">
        </div>
        <div class="content">
          <div v-if="typeof(user.bio) === 'string'">
            <p>{{ user.bio }}</p>
          </div>
          <div v-else>
            <p v-for="l in user.bio">{{ l }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BioDetail",
  props: {
    user: {
      type: Object,
    },
  },
  methods:{
    closeDetail(){
      this.$emit("closeDetail")
    },
  }
};
</script>

<style lang="scss" scoped>
@import "bio";
</style>