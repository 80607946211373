import store from "../store/index";

let stopTouchAndScrollEventPropagation = (el) => {
  let eventList = [
    "touchstart",
    "touchmove",
    "touchend",
    "touchcancel",
    "wheel",
    "mousewheel",
  ];
  for (var i = 0; i < eventList.length; i++) {
    el.addEventListener(eventList[i], function (event) {
      event.stopPropagation();
    });
  }
};

let iframeBugCorrection = (viewer) => {
  const iframes = document.getElementsByTagName('iframe');
  const dragCtl = viewer.controls().method('mouseViewDrag').instance;

  dragCtl.addEventListener('active', () => {
    for (const el of iframes) {
      el.style.pointerEvents = 'none';
    }
  });

  dragCtl.addEventListener('inactive', () => {
    for (const el of iframes) {
      el.style.pointerEvents = 'all';
    }
  });
};

let findSceneById = (id) => {
  const scenes = store.state.scenes.scenes
  for (let i = 0; i < scenes.length; i++) {
    if (scenes[i].id === id) {
      return { id: i, scene: scenes[i] };
    }
  }
  return null;
};

let createNavigationLinkHotspot = (hotspot) => {
  // Create wrapper element to hold icon and tooltip.
  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  wrapper.classList.add('link-hotspot');
  if (hotspot.customClass) wrapper.classList.add(hotspot.customClass)
  // Create image element.
  let icon;
  if (hotspot.mdi === null){
    icon = document.createElement('img');
    icon.src = 'img/link.png';
    icon.classList.add('link-hotspot-icon');
  } else {
    icon = document.createElement('span');
    icon.classList.add('mdi')
    icon.classList.add(hotspot.mdi);
  }
  // Set rotation transform.
  if (hotspot.perspective === null) {
    var transformProperties = [ '-ms-transform', '-webkit-transform', 'transform' ];
    for (var i = 0; i < transformProperties.length; i++) {
      var property = transformProperties[i];
      icon.style[property] = 'rotateZ(' + hotspot.rotation.z + 'deg)' + ' rotateY(' + hotspot.rotation.y + 'deg)' + ' rotateX(' + hotspot.rotation.x + 'deg)' ;
    }
  }

  // Add click event handler.
  wrapper.addEventListener('click', function() {
    store.state.nextRoom = findSceneById(hotspot.target).id;
    if (hotspot.destinationViewParameters) store.state.nextRoomParameters = hotspot.destinationViewParameters;
  });

  // Prevent touch and scroll events from reaching the parent element.
  // This prevents the view control logic from interfering with the hotspot.
  stopTouchAndScrollEventPropagation(wrapper);

  // Create tooltip element.
  /*var tooltip = document.createElement('div');
  tooltip.classList.add('hotspot-tooltip');
  tooltip.classList.add('link-hotspot-tooltip');
  tooltip.innerHTML = findSceneById(hotspot.target).scene.name;*/

  let infoOnMouse = document.getElementById('info_on_mouse');
  let offsetX;
  let offsetY = 80;
  icon.addEventListener('mouseenter', function(){
    infoOnMouse.innerHTML = findSceneById(hotspot.target).scene.name;
    if(infoOnMouse.innerHTML.length > 1) infoOnMouse.classList.add('visible');
    offsetX = infoOnMouse.clientWidth / 2;

  });
  icon.addEventListener('mouseleave', function(){
    infoOnMouse.classList.remove('visible')
    infoOnMouse.innerHTML = '';
    offsetX = 0;
  });
  window.addEventListener('mousemove', (e) => {
    if (offsetX != infoOnMouse.clientWidth / 2) offsetX = infoOnMouse.clientWidth / 2;
    if(infoOnMouse.classList.contains('visible')){
      infoOnMouse.style.left = e.clientX - offsetX + 'px'
      infoOnMouse.style.top = e.clientY - offsetY + 'px'
    }
  });

  wrapper.appendChild(icon);
  //wrapper.appendChild(tooltip);
  return wrapper;
};

let createIframeVideosHotspots = (hotspot) => {

  const videosData = hotspot.videosData

  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  if (videosData.length > 1){
    wrapper.classList.add('videos-hotspot');
  } else {
    wrapper.classList.add('single-video-hotspot');
  }

  let iframe = document.createElement('iframe');
  iframe.classList.add('switch-scene-reset-iframe')
  iframe.width = hotspot.width
  iframe.height = hotspot.height



  iframe.setAttribute('frameborder', 0);
  iframe.setAttribute('allowfullscreen', true);
  //iframe.setAttribute('id', 'video-0')
  iframe.src = videosData[0].url

  wrapper.appendChild(iframe);

  if (videosData.length > 1){
    let menu =  document.createElement('div')
    menu.classList.add('videos-menu');
    let title = document.createElement('h1')
    title.innerHTML = 'Playlist'
    menu.appendChild(title)
    videosData.forEach((video, index) => {
      const link = document.createElement('a')
      link.innerHTML = video.name
      link.setAttribute('data-url', video.url)
      link.classList.add('btn-video-swap')
      menu.appendChild(link)
      if (index === 0) {
        link.classList.add('current-video')
      }
      link.addEventListener('click', function(e){
        e.preventDefault()
        let container = link.parentNode.parentNode
        link.parentNode.childNodes.forEach(links => {
          links.classList.remove('current-video')
        });
        this.classList.add('current-video')
        // change iframe url
        const iframe = Array.from(container.childNodes).find(child => child.nodeName === 'IFRAME')
        iframe.src = link.dataset.url;
      })
    });

    wrapper.appendChild(menu);
  }


  stopTouchAndScrollEventPropagation(wrapper);
  return wrapper;
};

let createPdfHotspots = (hotspot) => {

  // Create wrapper element to hold icon and tooltip.
  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  wrapper.classList.add('pdf-hotspot');
  wrapper.classList.add('link-hotspot');


  // Create image element.
  let iconContainer = document.createElement('div');
  let icon = document.createElement('img');
  icon.src = 'img/eye.png';
  iconContainer.classList.add('pdf-hotspot-icon');
  let sticker = document.createElement('span');
  let pdfNumber = hotspot.target.split('_', 1)
  let array_from_number = pdfNumber[0].split('.')
  if (array_from_number[1].length == 1){
    array_from_number[1] = "0" + array_from_number[1]
    sticker.innerHTML = array_from_number.join(".")
  } else {
    sticker.innerHTML = hotspot.target.split('_', 1);
  }
  sticker.classList.add('pdf-sticker');
  iconContainer.appendChild(icon);
  iconContainer.appendChild(sticker);

  // Prevent touch and scroll events from reaching the parent element.
  // This prevents the view control logic from interfering with the hotspot.
  wrapper.addEventListener('click', function(event) {
    store.state.clickX = event.clientX
    store.state.clickY = event.clientY
    store.state.currentPdf = hotspot.subfolder + hotspot.target
    store.state.pdfIsOpen = true
  });

  wrapper.appendChild(iconContainer);
  /* wrapper.appendChild(tooltip); */
  stopTouchAndScrollEventPropagation(wrapper);
  return wrapper;
};

let createImgHotspots = (hotspot) => {
  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  wrapper.classList.add('img-hotspot');

  let imgHtml = document.createElement('img');
  imgHtml.src = "img/" + hotspot.subfolder + hotspot.target;
  imgHtml.setAttribute('ondragstart', "return false;");
  imgHtml.style['user-drag'] = 'none';
  imgHtml.style['user-select'] = 'none';
  imgHtml.style['-moz-user-select'] = 'none';
  imgHtml.style['-webkit-user-drag'] = 'none';
  imgHtml.style['-webkit-user-select'] = 'none';
  imgHtml.style.filter = 'brightness(0.9)'
  wrapper.appendChild(imgHtml)
  return wrapper;
};

let createExternalLinkHotspots = (hotspot) => {
  console.log(hotspot)
  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot');
  wrapper.classList.add('button-hotspots');

  let link = document.createElement("a")
  link.href = hotspot.url
  link.innerHTML = hotspot.content
  link.target = "_blank"

  wrapper.appendChild(link)

  return wrapper
}

export default {
  stopTouchAndScrollEventPropagation,
  iframeBugCorrection,
  findSceneById,
  createNavigationLinkHotspot,
  createIframeVideosHotspots,
  createPdfHotspots,
  createImgHotspots,
  createExternalLinkHotspots,
};
